<template>
    <div v-frag>
        <div>
            <embed :src="url" class="iframe"/>
        </div>
    </div>
</template>

<script>
    import countryToCurrency from "country-to-currency";
    import { mapGetters } from "vuex";
    import {CASINO} from "@/config/casino_confs";
    export default {
        data(){
            return{
                url:""
            }
        },
        computed: {
            ...mapGetters({
                geo:'geo',
                user: 'user',
            }),
            currency() {
                if(this.user) {
                    return (this.user.iso == null) ? 'USD' : countryToCurrency[this.user.iso]
                } else {
                    return (this.geo == null) ? 'USD' : countryToCurrency[this.geo?.country_code2]
                }
            },
        },
        async mounted(){
            const gameId = this.$route.params.gameId
            const data = await CASINO.launchGameL(gameId,this.currency.toUpperCase(),this.$i18n.locale,this.geo?.country_code2,'DEMO');
            this.url = data.gameURL;
        }

    }
 </script>

<style scoped>
    .iframe {
    background: #000;
        border: none;
        width: 100%;
        min-height: 85vh;
    }
</style>